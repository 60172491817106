import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GoTo = makeShortcode("GoTo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "optional-topics"
    }}>{`Optional topics`}</h2>
    <p>{`You’ve now completed the core topics of this module. The rest of the module contains some extra topics that will apply to many families during the pandemic.`}</p>
    <p>{`We encourage you to complete as many of these topics as you like. If you don’t feel the topics are relevant for you at the moment, you can skip them by clicking the `}<strong parentName="p">{`‘skip topic’`}</strong>{` button at the start of each topic. This will take you to the next topic.`}</p>
    <p>{`If you change your mind in future, you can always come back to this module and complete any of the optional topics.`}</p>
    <p><strong parentName="p">{`The optional topics are:`}</strong></p>
    <ul>
  <li>Working from home</li>
  <li>Supporting your teen’s online learning, including transitioning back to face-to-face schooling</li>
  <li>Supporting your teen if a loved one contracts COVID-19, including coping with grief if a loved one dies</li>
  <li>Dealing with increased risk of family violence during the pandemic.</li>
    </ul>
    <GoTo to="/m2-parenting-in-pandemic/29-seek-help" mdxType="GoTo">Click here to skip all of these topics</GoTo> and proceed to the end of
the module.

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      